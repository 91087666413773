import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";

const defaultTimeFormat = "h:mm a";
const defaultDateTimeFormat = `MMM d, ${defaultTimeFormat}`;
const defaultDateFormat = "MM/dd/yyyy";

/**
 * Formats a date string to a given pattern.
 *
 * The backend sends date strings after converting them to the local time of the ride.
 * Because time zone information is missing on date strings, JavaScript assumes that the
 * date string should be in the local time of the browser. This causes the date to change
 * when the Date object is created.
 *
 * To prevent this from happening, we create the Date object as a UTC date before formatting it.
 *
 * @example formatDate("2021-08-31", "MM/dd/yyyy");
 * @param {string} date - The date string to format.
 * @param {string} pattern - The pattern to format the date string.
 * @returns {string} - The formatted date string.
 */
const formatDate = (date, pattern = defaultDateFormat) => {
  const utcDate = utcToZonedTime(new Date(date), "Etc/UTC");
  return format(utcDate, pattern);
};

/**
 * Formats a date-time string to a given pattern.
 *
 * The backend sends date-time strings after converting **some** of them to the local time of the ride.
 * Because time zone information may be missing on some date-time strings, JavaScript assumes that the
 * date-time string should be in the local time of the browser. This causes the date-time to change
 * when the Date object is created.
 *
 * To prevent this from happening, we create the Date object as a UTC date before formatting it.
 *
 * DO NOT USE THIS METHOD IF `pattern` INCLUDES TIMEZONE INFORMATION, AS IT WILL ALWAYS BE UTC.
 *
 * @example formatDateTime("2021-08-31T12:00:00", "MM/dd/yyyy hh:mm a");
 * @param {string} datetime - The date-time string to format.
 * @param {string} pattern - The pattern to format the date-time string.
 * @returns {string} - The formatted date-time string.
 */
const formatDateTime = (datetime, pattern = defaultDateTimeFormat) => {
  datetime = datetime.endsWith("Z") ? datetime : datetime + "Z";
  const utcDatetime = utcToZonedTime(new Date(datetime), "Etc/UTC");
  return format(utcDatetime, pattern);
};

const timeFormatWithTz = (time, tzName, pattern = defaultTimeFormat) => {
  return formatInTimeZone(new Date(time), tzName, pattern);
};

const dateTimeFormatWithTz = (
  time,
  tzName,
  pattern = defaultDateTimeFormat
) => {
  return formatInTimeZone(new Date(time), tzName, pattern);
};

export { timeFormatWithTz, dateTimeFormatWithTz, formatDate, formatDateTime };
