import React from "react";
import { Box } from "@mui/material";
import BidsTable from "./BidsTable";
import { timeFormatWithTz } from "../../utils/TimeUtils";

const BestTimeBidBoard = ({
  biddingWindowEndsAt,
  requestedStartTime,
  startLocationTzName,
  bestTimeBids,
}) => {
  const styles = {
    mainContainer: {
      borderTop: "1px solid lightgrey",
      padding: "20px",
    },
    headingsContainer: {
      marginBottom: "20px",
    },
    paragraph: {
      fontSize: "15px",
    },
    span: {
      fontWeight: 600,
    }
  }
  const biddingWindowEnd = timeFormatWithTz(biddingWindowEndsAt, startLocationTzName)

  return(
    <div style={styles.mainContainer}>
      <Box sx={styles.headingsContainer}>
        <h2>Best Time Bids</h2>
        <p style={styles.paragraph}>Requested Start Time: <span style={styles.span}>{requestedStartTime}</span></p>
        <p style={styles.paragraph}>Bidding Window End: <span style={styles.span}>{biddingWindowEnd}</span></p>
      </Box>
      <BidsTable
        bestTimeBids={bestTimeBids}
        startLocationTzName={startLocationTzName}
      />
    </div>
  )
};

export default BestTimeBidBoard;