import React from "react";
import { FontWeight } from "../../globalStyles/emotion";
import EndTime from "./EndTime";
import SlashDateFormat from "./SlashDateFormat";
import StartTime from "./StartTime";

const DashTimeFormat = ({
  requestedStartDateDisplay,
  requestedStartTimeDisplay,
  requestedEndTimeDisplay,
}) => {
  return (
    <>
      <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
        <SlashDateFormat
          requestedStartDateDisplay={requestedStartDateDisplay}
        />
        <StartTime requestedStartTimeDisplay={requestedStartTimeDisplay} />
        -
        <EndTime requestedEndTimeDisplay={requestedEndTimeDisplay} />
      </div>
    </>
  );
};

export default DashTimeFormat;
