import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { timeFormatWithTz } from "../../utils/TimeUtils";

const BestTimeSelectionSection = ({
  bestTimeOptions,
  selectedBestTime,
  setSelectedBestTime,
  startLocationTzName,
}) => {
  const renderItems = (bestTimeOptions) => {
    return bestTimeOptions.map((option, index) => (
      <MenuItem key={index} value={option}>
        {timeFormatWithTz(option, startLocationTzName)}
      </MenuItem>
    ));
  }

  const handleSelectTime = (time) => {
    setSelectedBestTime(time)
  }

  return (
    <>
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel id="time-simple-select-label">Best Time Bid</InputLabel>
        <Select
          labelId="time-simple-select-label"
          id="time-simple-select"
          label="Best Time Bid"
          value={selectedBestTime}
          onChange={(event) => handleSelectTime(event.target.value)}
        >
          {bestTimeOptions ? renderItems(bestTimeOptions) : null}
        </Select>
      </FormControl>
    </>
  )
};

export default BestTimeSelectionSection;