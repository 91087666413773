import { useState } from "react";
import axios from "axios";
import Environment from "../../constants/Environment";
import { useRef } from "react";
import { fetchTransportPartner } from "../../axiosHandlers/RideExportHandlers";
import { useAsyncEffect } from "../../utils/CustomHooks";
import { noDataMessage, pageConfig } from "./Config";

const { exportApiUrl } = Environment;
const exportBaseUrl = `${exportApiUrl}/FilteredRides/GetRides?`;

const DownloadCsvHooks = (exportData) => {
  const [partnerId, setPartnerId] = useState(/** @type string */ null);
  const [dateData, setDateData] = useState(/** @type integer */ null);
  const [partnerList, setPartnerList] = useState(
    /** @type string */ null
  );
  const [isLoading, setIsLoading] = useState(/** @type boolean */ false);
  const [fetchedData, setFetchedData] = useState([]);
  const [noDataAlert, setNoDataAlert] = useState(/** @type string */ null);
  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const pageData = pageConfig(exportData);

  useAsyncEffect(async () => {
    const { data } = await fetchTransportPartner();
    setPartnerList(data);
  }, []);

  const handleTime = ({ target }) => {
    setDateData(target.value);
  };

  const handlePartner = ({ target }) => {
    setPartnerId(target.value);
  };

  const requestExport = (url) => {
    axios
      .get(url)
      .then(({ data }) => {
        if (data && data.length > 0) {
          setIsLoading(true);
          setFetchedData(data);
          setTimeout(() => {
            csvLink.current.link.click();
            setIsLoading(false);
          });
          setNoDataAlert(null);
        } else {
          setNoDataAlert(noDataMessage);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const partnerIdFromStorage = parseInt(localStorage.getItem("partnerId"));
  const withPartner = partnerId || partnerIdFromStorage || null;

  const buildPartnerQuery = () => {
    const withPartnerId = withPartner ? `transportPartnerId=${withPartner}&` : "";

    return `${withPartnerId}`;
  }

  const buildQuery = () => {
    const partnerQuery = buildPartnerQuery();
    const ridesStateQuery = `ridesState=${exportData}`;
    const useDateRange = dateData !== null && exportData !== "upcoming" ? `&dateRange=${dateData}` : "";

    return `${partnerQuery}${ridesStateQuery}${useDateRange}`;
  };

  const handleExportRequest = () => {
    if (withPartner) {
      const query = buildQuery();

      const request = `${exportBaseUrl}${query}`;

      requestExport(request);
    }
  };

  return {
    handleTime,
    handlePartner,
    handleExportRequest,
    pageData,
    partnerId,
    dateData,
    partnerList,
    isLoading,
    fetchedData,
    csvLink,
    noDataAlert,
    setNoDataAlert,
  };
};

export default DownloadCsvHooks;
