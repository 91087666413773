import { FontWeight } from "../../globalStyles/emotion";

import "./Payout.css";
const Payout = ({ bonus, estimatedPayout, actualTotal, estimatedTotal }) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  estimatedPayout = formatter.format(estimatedPayout);
  bonus = formatter.format(bonus);

  let total = formatter.format(estimatedTotal);

  // On the HistoryView, use actual total instead of estimated total
  // If the actual total hasn't been calculated yet, display "TBD"
  if (actualTotal !== undefined && actualTotal !== null) {
    total = formatter.format(actualTotal);
  } else if (actualTotal !== undefined && actualTotal === null) {
    total = "TBD";
  }

  return (
    <>
      <div style={{ display: "flex", gap: "5px" }}>
        <p style={{ width: "62px" }}>Payout: </p>
        <FontWeight>{estimatedPayout}</FontWeight>
      </div>

      {localStorage.getItem("Page") === "Dispatch" ? null : (
        <div style={{ display: "flex", gap: "5px" }}>
          <p style={{ width: "62px" }}> Total: </p>{" "}
          <FontWeight>{total}</FontWeight>
        </div>
      )}
    </>
  );
};

export default Payout;
