/**
 * Retrieves the name of the environment.
 *
 * @returns Returns the environment name.
 */
function getEnvironment() {
  // This is sort of a hack to figure out which environment we're in. It would
  // be good to find a way to read this from an environment variable, but since
  // promoting an app to a new environment in Heroku doesn't do a build, it will
  // use the same environment variables as the previous environment, which would
  // always be Dev.
  const url = window.location.host;

  if (
    url.startsWith("onward-dispatcher-web-prod.") ||
    // We must also check the domain here because the old Staging subdomain was
    // also just `dispatcher`.
    url.startsWith("dispatcher.onwardrides")
  ) {
    return "production";
  }

  if (
    url.startsWith("onward-dispatcher-web-staging.") ||
    url.startsWith("staging-dispatcher.") ||
    url.startsWith("dispatcher.joinonward") // This is the old subdomain
  ) {
    return "staging";
  }

  if (
    url.startsWith("onward-dispatcher-web-dev.") ||
    url.startsWith("dev-dispatcher.")
  ) {
    return "dev";
  }

  if (url.startsWith("localhost")) {
    return "localhost";
  }

  throw Error("Unable to determine the environment");
}

const ENV = {
  local: {
    apiUrl: "/api",
    onwardApiUrl: "http://localhost:3000",
    onwardDashboardUrl: "http://localhost:3002",
    onwardWsUrl: "wss://localhost:3000/cable",
    webAppHost: "http://localhost:5164",
    app: "dispatcher",
    exportApiUrl: "https://localhost:7004/api",
  },
  dev: {
    apiUrl: "https://dev-dispatcher-api.joinonward.net/api",
    onwardApiUrl: "https://dev-api.joinonward.net",
    onwardDashboardUrl: "https://dev-dashboard.joinonward.net",
    onwardWsUrl: "wss://onward-api-ws-dev.herokuapp.com/cable",
    webAppHost: "https://dev-dispatcher.joinonward.net",
    app: "dispatcher",
    exportApiUrl: "https://onward-export-api-dev.herokuapp.com/api",
  },
  staging: {
    apiUrl: "https://staging-dispatcher-api.joinonward.net/api",
    onwardApiUrl: "https://staging-api.joinonward.net",
    onwardDashboardUrl: "https://staging-dashboard.joinonward.net",
    onwardWsUrl: "wss://onward-api-ws-staging.herokuapp.com/cable",
    webAppHost: "https://staging-dispatcher.joinonward.net",
    app: "dispatcher",
    exportApiUrl: "https://onward-export-api-staging.herokuapp.com/api",
  },
  production: {
    apiUrl: "https://dispatcher-api.onwardrides.com/api",
    onwardApiUrl: "https://api.onwardrides.com",
    onwardDashboardUrl: "https://dashboard.onwardrides.com",
    onwardWsUrl: "wss://onward-api-ws-prod.herokuapp.com/cable",
    webAppHost: "https://dispatcher.onwardrides.com",
    app: "dispatcher",
    exportApiUrl: "https://onward-export-api-prod.herokuapp.com/api",
  },
};

/**
 * Get the config for the given environment.
 * @param env The environment to get the config for.
 * @returns The config for the given environment.
 */
function getEnvVars(env = "") {
  if (env && env.indexOf("staging") !== -1) return ENV.staging;
  if (env && env.indexOf("production") !== -1) return ENV.production;
  if (env && env.indexOf("dev") !== -1) return ENV.dev;
  return ENV.local;
}

export default getEnvVars(getEnvironment());
export { getEnvironment };
