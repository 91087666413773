import { Tooltip } from "@mui/material";
import React from "react";
import { FontWeight } from "../../globalStyles/emotion";
import { PurpleButton } from "../../globalStyles/emotion";
import MncsDialog from "../mncsDialog/MncsDialog";

const LocationInformation = ({
  startLocationName,
  startLocationAddress,
  startLocationNotes,
  endLocationName,
  endLocationNotes,
  endLocationAddress,
  startLocationRoomNumber,
  endLocationRoomNumber,
  rideNotes,
  mncsAnswers,
}) => {
  if (rideNotes === null) {
    rideNotes = "";
  }
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <p>Pickup Location: </p>{" "}
          <>
            {startLocationName === startLocationAddress ? null : (
              <FontWeight>{startLocationName}</FontWeight>
            )}
          </>
          <FontWeight>{startLocationAddress}</FontWeight>
        </div>
        {startLocationNotes == null ? null : (
          <>
            {" "}
            <p style={{}}>Start Location Notes:</p>{" "}
            {startLocationNotes.length > 50 ? (
              <Tooltip
                enterDelay={500}
                title={startLocationNotes}
                placement="top"
              >
                <FontWeight>{startLocationNotes.slice(0, 50)}...</FontWeight>
              </Tooltip>
            ) : (
              <FontWeight> {startLocationNotes}</FontWeight>
            )}
          </>
        )}

        <div style={{ display: "flex", gap: "5px" }}>
          <p>Pickup Room Number: </p>{" "}
          {startLocationRoomNumber == null ? (
            <FontWeight> N/A</FontWeight>
          ) : (
            <FontWeight> {startLocationRoomNumber}</FontWeight>
          )}
        </div>
      </div>

      <>
        {" "}
        <p>Drop-off Location: </p>
        <>
          {endLocationName === endLocationAddress ? null : (
            <FontWeight>{endLocationName}</FontWeight>
          )}
        </>
        <FontWeight>{endLocationAddress}</FontWeight>
      </>

      {endLocationNotes == null ? null : (
        <>
          {" "}
          <p>End Location Notes</p>{" "}
          {endLocationNotes.length > 50 ? (
            <Tooltip enterDelay={500} title={endLocationNotes} placement="top">
              <FontWeight>{endLocationNotes.slice(0, 50)}...</FontWeight>
            </Tooltip>
          ) : (
            <FontWeight> {endLocationNotes}</FontWeight>
          )}{" "}
        </>
      )}

      <div style={{ display: "flex", gap: "5px" }}>
        <p>Drop-off Room Number:</p>{" "}
        {endLocationRoomNumber == null ? (
          <FontWeight> N/A</FontWeight>
        ) : (
          <FontWeight> {endLocationRoomNumber}</FontWeight>
        )}
      </div>

      {rideNotes == null || rideNotes == "" ? null : (
        <>
          {" "}
          <p>Ride Notes:</p>{" "}
          {rideNotes.length > 50 ? (
            <Tooltip enterDelay={500} title={rideNotes} placement="top">
              <FontWeight>{rideNotes.slice(0, 50)}...</FontWeight>
            </Tooltip>
          ) : (
            <FontWeight> {rideNotes}</FontWeight>
          )}{" "}
        </>
      )}
      {mncsAnswers.length === 0 ? (
        ""
      ) : (
        <>
          <div className="display-button-flex">
            <PurpleButton onClick={handleOpen}>
              Medical Necessity Certification Statement
            </PurpleButton>
          </div>
          <MncsDialog
            mncsAnswers={mncsAnswers}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
          />
        </>
      )}
    </>
  );
};

export default LocationInformation;
