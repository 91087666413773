import React from "react";
import { formatDate } from "../../utils/TimeUtils";
import { FontWeight } from "../../globalStyles/emotion";

const SlashDateFormat = ({ requestedStartDateDisplay }) => {
  return (
    <FontWeight>
      {formatDate(requestedStartDateDisplay, "MM/dd/yyyy")}
    </FontWeight>
  );
};

export default SlashDateFormat;
