import { TableRow } from "@mui/material";
import {
  CustomTableCell,
  TextTransformButton,
} from "../../globalStyles/emotion";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const TableHeader = ({
  sortHeader,
  setSortHeader,
  sortDirection,
  setSortDirection,
  showCreatedTime,
}) => {
  return (
    <TableRow
      style={{
        background: "white",
        borderTopLeftRadius: "25px",
        height: "70px",
        padding: "0px",
        width: "100%",
      }}
    >
      <CustomTableCell
        align="left"
        style={{
          borderTopLeftRadius: "25px",
          minWidth: "160px",
          maxWidth: "160px",
          padding: "10px",
        }}
      />

      <CustomTableCell
        style={{
          minWidth: showCreatedTime ? "200px" : "320px",
          maxWidth: showCreatedTime ? "200px" : "320x",
          padding: "10px",
        }}
        colSpan={showCreatedTime ? null : "2"}
      >
        <TextTransformButton style={{ textTransform: "none" }}>
          Rider
        </TextTransformButton>
      </CustomTableCell>

      {showCreatedTime && (
        <CustomTableCell
          align="left"
          style={{ minWidth: "120px", maxWidth: "120px", padding: "10px" }}
        >
          <TextTransformButton
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSortDirection(() =>
                sortHeader === "availableAtLocal" ? sortDirection * -1 : 1
              );
              setSortHeader("availableAtLocal");
            }}
          >
            Available Time
            {sortHeader === "availableAtLocal" &&
              (sortDirection === 1 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              ))}
          </TextTransformButton>
        </CustomTableCell>
      )}

      <CustomTableCell align="left" style={{ padding: "10px" }}>
        <TextTransformButton
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSortDirection(() =>
              sortHeader === "requestedStartTimeLocal" ? sortDirection * -1 : 1
            );
            setSortHeader("requestedStartTimeLocal");
          }}
        >
          Ride Time
          {sortHeader === "requestedStartTimeLocal" &&
            (sortDirection === 1 ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            ))}
        </TextTransformButton>
      </CustomTableCell>
    </TableRow>
  );
};

export default TableHeader;
